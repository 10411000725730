import type { EventBusKey } from "@vueuse/core";

export enum MenuType {
  Mobile = "mobile",
  Desktop = "desktop",
}
export interface MenuAction {
  menuType: MenuType;
}

export const actionMenuKey: EventBusKey<MenuAction> = Symbol("action-menu");
